var TRACKING = {
    debug: false,

    log: function (data) {
        if (TRACKING.debug) {
            console.log('TRACKING.' + arguments.callee.caller.name + '(): ', data);
            console.log('DATALAYER', window.dataLayer);
        }
    },

    trackEvent: function (category, action, label, value, callback) {
        if (value === undefined) {
            value = 0;
        }
        if (callback === undefined) {
            callback = function () {};
        }

        if (window.dataLayer) {
            var timeoutId;
            var gtmCallback = callback;

            if (!!window.google_tag_manager) {
                gtmCallback = function() {
                    clearTimeout(timeoutId);
                    callback();
                };
                // If GTM gets in a real mess, step in.
                timeoutId = setTimeout(gtmCallback, 2500);
            }

            // Let GTM handle the redirect once it's done its thing.
            window.dataLayer.push({
                'event': 'd3r.event',
                'eventCategory': category,
                'eventAction': action,
                'eventLabel': label,
                'eventValue': value,
                'eventCallback': gtmCallback,
                'eventTimeout': 2000
            });

            if (!window.google_tag_manager) {
                callback();
            }
        }

        TRACKING.log([category, action, label, parseInt(value), callback]);
    },

    trackPageview: function (url) {
        if (window.dataLayer) {
            window.dataLayer.push({
                'event': 'd3r.pageview',
                'trackingUrl': url,
            });
        }

        TRACKING.log(url);
    },

    trackAddProduct: function (data) {
        if (window.dataLayer) {
            window.dataLayer.push({
                'event': 'addToCart',
                'ecommerce': {
                    'currencyCode': data.currency,
                    'add': {
                        'products': [data.tracking_item]
                    }
                }
            });
        }
        TRACKING.log(data.tracking_item);
    },
};
